// React
import React, { useRef, useState, useEffect } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { transitionNavigate } from 'transition';
import { localizeLink } from 'utilities';

// Components
import TilePattern from 'components/tilePattern';
import Image from 'components/image';
import Button from 'components/button';
import PromotionalProductInner from './../../promotionalProducts/components/promotionalProduct/promotionalProductInner.component';
import Ticker from 'components/ticker';

const HeroComponent = props => {
    // Content
    const {
        parentSlugs,
        content: {
            callToActionLink,
            callToActionText,
            media,
            product,
            subtitle,
            title,
            landingPageHeaderTextColor,
            triangleHeight,
        },
        ticker,
        location,
        currentIndex,
        index,
        pageContext: { locale },
        pageContext,
    } = props;

    const [theme] = useGlobal('pageTheme');
    const [, setHeaderTheme] = useGlobal('headerTheme');
    const [textTheme, setTextTheme] = useState(theme);

    // OVERWRITE THEME COLOR FOR TEXT
    useEffect(() => {
        setTimeout(() => {
            if (landingPageHeaderTextColor === 'white') {
                setHeaderTheme(s.color('black'));
                setTextTheme(s.color('black'));
            } else if (landingPageHeaderTextColor === 'black') {
                setHeaderTheme(s.color('white'));
                setTextTheme(s.color('white'));
            }
        }, 100);
    }, [landingPageHeaderTextColor]);

    const heroRef = useRef(null);
    const videoRef = useRef(null);

    // ParentHeight
    const [heroHeight, setHeroHeight] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            heroRef.current &&
                setHeroHeight(heroRef.current.getBoundingClientRect().height);
        }, 1000);
    }, []);

    // Get index based states
    const [state, setState] = useState({
        active: currentIndex === index,
        closest: currentIndex + 1 === index || currentIndex - 1 === index,
        previous: currentIndex > index,
        next: currentIndex < index,
    });
    useEffect(() => {
        setState({
            active: currentIndex === index,
            closest: currentIndex + 1 === index || currentIndex - 1 === index,
            previous: currentIndex > index,
            next: currentIndex < index,
        });
    }, [currentIndex]);

    // Video play/pause
    useEffect(() => {
        if (videoRef && videoRef.current) {
            if (currentIndex === index) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [currentIndex]);

    // Methods
    const getSlug = ctaLink => {
        const apiKey = ctaLink.model.apiKey;
        let preSlug;
        switch (apiKey) {
            case 'blog_post':
            case 'course':
            case 'brew_guide':
                preSlug = `/${parentSlugs[apiKey].slug}`;
                break;
            case 'espresso':
            case 'filter_coffee':
            case 'presale_product':
                preSlug = `/${parentSlugs.shop.slug}`;
                break;
            default:
                preSlug = ``;
                break;
        }

        return localizeLink(`${preSlug}/${callToActionLink.slug}/`, locale);
    };

    // Is it product?
    const isProduct = product;

    // Video animate in
    const [mediaLoaded, setMediaLoaded] = useState(false);
    useEffect(() => {
        function checkLoad() {
            if (!media.isImage) {
                if (
                    videoRef &&
                    videoRef.current &&
                    videoRef.current.readyState === 4
                ) {
                    setMediaLoaded(true);
                } else {
                    setTimeout(checkLoad, 100);
                }
            } else {
                setTimeout(setMediaLoaded(true), 400);
            }
        }
        checkLoad();
    }, []);

    return (
        <>
            <Hero {...{ textTheme, theme, state, isProduct }} ref={heroRef}>
                <TileWrapper {...{ state }}>
                    <TilePattern
                        {...{
                            theme,
                            pattern: isProduct ? 'standard' : 'hero',
                            parentHeight: heroHeight,
                            darken: isProduct,
                            maxHeight: isProduct ? null : triangleHeight,
                        }}
                    />
                </TileWrapper>
                <Inner {...{ state, isProduct }}>
                    {isProduct ? (
                        <PromotionalProductInner
                            {...{
                                product: {
                                    ...product,
                                    thumbnail: media,
                                    name: title,
                                },
                                overwrittenSubtitle: subtitle,
                                pageContext,
                                location,
                                state,
                            }}
                        />
                    ) : (
                        <>
                            <SubTitle>{subtitle}</SubTitle>
                            <Title>{title}</Title>
                            {callToActionLink && callToActionText && (
                                <div>
                                    <Button
                                        {...{
                                            onClick() {
                                                transitionNavigate({
                                                    from: location,
                                                    to: getSlug(
                                                        callToActionLink
                                                    ),
                                                });
                                            },
                                            theme: textTheme,
                                            label: callToActionText,
                                            style: ButtonStyle,
                                        }}
                                    />
                                </div>
                            )}

                            <Ticker {...{ text: ticker, theme, locale }} />
                        </>
                    )}
                </Inner>
                {!isProduct && (
                    <BackgroundImage mediaLoaded={mediaLoaded}>
                        {media.isImage ? (
                            <Image
                                {...{
                                    source: media.fluid,
                                    alt: '',
                                }}
                            />
                        ) : (
                            <video
                                ref={videoRef}
                                autoPlay
                                muted
                                loop
                                playsInline>
                                <source src={media.url} type="video/mp4" />
                            </video>
                        )}
                    </BackgroundImage>
                )}
            </Hero>
        </>
    );
};

const animateMediaIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Hero = styled.div`
    ${props => true && s.layout.fullWidthBackground(props.theme.default())};
    color: ${props => props.textTheme.text()};

    position: absolute;
    display: flex;

    flex-direction: column;
    align-items: center;

    height: 100vh;
    width: 100vw;

    will-change: transform;
    transform: translateY(100%);
    transition: transform 0.7s ${s.easings.smooth.out};

    overflow: hidden;

    /* active */
    ${props =>
        props.state.active &&
        css`
            visibility: visible;
            transform: translateY(0%);
        `}

    /* closest */
    ${props =>
        props.state.closest &&
        css`
            visibility: visible;
        `}

    /* previous */
    ${props =>
        props.state.previous &&
        css`
            transform: translateY(-100%);
        `}
`;

const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    opacity: 0;

    animation-name: ${animateMediaIn};
    animation-duration: 1200ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0;
    animation-play-state: ${props =>
        props.mediaLoaded ? 'running' : 'paused'};
    animation-timing-function: ${s.easings.smooth.out};

    &::before {
        position: absolute;
        content: '';
        background: rgba(0, 0, 0, 0.35);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .gatsby-image-wrapper {
        height: 100%;
    }

    img {
        max-width: 100%;
        object-fit: cover;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const TileWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    left: 0;
    will-change: transform;
    transform: translateY(-110%);
    transition: transform 0.7s ${s.easings.smooth.out};

    /* active */
    ${props =>
        props.state.active &&
        css`
            transform: translateY(0%);
        `}

    /* previous */
    ${props =>
        props.state.previous &&
        css`
            transform: translateY(110%);
        `}
`;

const Inner = styled.div`
    ${s.grid.contentWidth()};
    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    ${props =>
        s.responsive.property('margin-top', {
            20: props.isProduct ? 0 : -100,
        })};

    display: flex;
    flex-direction: column;
    justify-content: ${props => (props.isProduct ? 'flex-start' : 'center')};

    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    will-change: transform;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.7s ${s.easings.smooth.out}, opacity 0.7s;

    /* active */
    ${props =>
        props.state.active &&
        css`
            transform: translateY(0%);
            opacity: 1;
        `}

    /* previous */
    ${props =>
        props.state.previous &&
        css`
            transform: translateY(100%);
            opacity: 0;
        `}
`;

const Title = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        10: 4,
        20: 6,
        30: 10,
        40: 8,
    })};

    ${s.fonts.style('h3')};

    position: relative;
    pointer-events: none;
`;

const SubTitle = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        10: 4,
        20: 6,
        30: 10,
        40: 8,
    })};

    ${s.fonts.style('h13')};

    margin-bottom: 2em;

    position: relative;
    pointer-events: none;
`;

const ButtonStyle = css`
    margin-top: 2em;
`;

export default HeroComponent;
